import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const qcInspectionApi = {
  get_inspecation: params => getNoCatch(`${topic.inspecationTopic}/get_inspecation`, params),
  upload_inspecation_documents: topic.inspecationTopic + '/upload_inspecation_documents',
  edit_inspecation: params => postNoCatch(`${topic.inspecationTopic}/edit_inspecation`, params),
  exlInspectionProd: params => getNoCatch(`${topic.inspecationTopic}/exlInspectionProd`, params),
  get_docus: params => getNoCatch(`${topic.inspecationTopic}/get_docus`, params)
};
