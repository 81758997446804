<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="flexV">
        计划验货开始日期：
        <el-date-picker
          size="small"
          class="topDateRangeSearch vg_mr_8"
          v-model="dateRange"
          @change="val => changeDateRange(val, 1)"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
        计划验货结束日期：
        <el-date-picker
          size="small"
          class="topDateRangeSearch vg_mr_8"
          v-model="dateRange2"
          @change="val => changeDateRange(val, 2)"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
        <el-button size="small" type="primary" icon="el-icon-search" @click="getProdControlList()"> 查询</el-button>
        <el-button type="info" size="small" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
        <el-button type="warning" size="small" @click="exportExcel()">导出Excel</el-button>
      </div>
      <div class="flexV vg_mt_8" style="height: 36px">
        <el-radio-group v-model="radioButton" size="small" @input="changeProdControlType" class="vg_mr_8">
          <el-radio-button size="small" label="成品"></el-radio-button>
          <el-radio-button size="small" label="材料"></el-radio-button>
        </el-radio-group>
        <el-upload
          ref="upload"
          :action="helper.modePrepend(qcInspectionApi.upload_inspecation_documents)"
          :before-upload="beforeUpload"
          :data="uploadData"
          :on-success="uploadSuccess"
          :show-file-list="true"
          multiple
          :limit="10"
          :on-exceed="handleExceed"
          class="flexHV vg_mr_8"
        >
          <el-button size="small" type="success">上传</el-button>
        </el-upload>
        <span class="vg_ml_8">文件大小不得超过 300MB!</span>
      </div>
      <DynamicUTable
        ref="multiTable"
        :tableData="tableData"
        :columns="tableProperties"
        :needPageSizes="false"
        :loadFlag="loadFlag"
        :totalPage="totalPage"
        :pageSize="50"
        :need-fixed-height="true"
        :need-search="true"
        :needChangeColumnProperties="false"
        :editActived="editActived"
        :editClosed="editClosed"
        :row-click="row => (currentRow = row)"
        :cellMouseEnter="(row, column) => (currentColumn = column.property)"
        :needCheckBox="true"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @getTableData="getProdControlList"
        :otherHeaderCellStyle="headerCellStyle"
        :show-summary="false"
      >
        <template v-slot:operation="scope">
          <el-link class="vg_mr_8 vg_cursor" size="mini" type="primary" @click="douVisible(scope.row)">查看</el-link>
        </template>
      </DynamicUTable>
    </el-card>
    <el-dialog title="查看文件" width="70%" :visible.sync="storageDocumentVisible">
      <documentList ref="documentList" :id="id" :inspecation_type="radioButton === '成品' ? 0 : 1"></documentList>
    </el-dialog>
  </div>
</template>

<script>
import { qcInspectionApi } from '@api/modules/qcInspection';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { prodTableProperties } from '@/views/InspectionManagement/qcInspectionManage/qcInspectionRouter';
import { downloadFile } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';
import { getDateNoTime } from '@assets/js/dateUtils';
import documentList from '@/views/InspectionManagement/qcInspectionManage/documentList.vue';
import helper from '@assets/js/helper';
import { getArrayIds } from '@assets/js/arrayUtils';

export default {
  name: 'ProdControlList',
  computed: {
    qcInspectionApi() {
      return qcInspectionApi;
    },
    helper() {
      return helper;
    },
    UrlEncode() {
      return UrlEncode;
    },
    tableProperties() {
      return this.prodTableProperties;
    }
  },
  components: {
    documentList,
    DynamicUTable
  },
  data() {
    return {
      id: 0,
      dateRange: [],
      dateRange2: [],
      prodTableProperties: cloneDeep(prodTableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      changeColumn: [],
      currentRow: {},
      currentColumn: '',
      radioButton: '成品',
      editActivedRow: {},
      dialogDateRange: [],
      storageDocumentVisible: false,
      storageRecordsTableData: [],
      beforeFlag: 0,
      successFlag: 0,
      uploadingFiles: [],
      uploadData: {}
    };
  },
  beforeDestroy() {
    if (JSON.stringify(this.currentRow) === '{}') return;
    this.saveRow(this.currentRow);
  },
  created() {},
  async mounted() {
    await this.initData();
  },
  methods: {
    getDateNoTime,
    handleExceed() {
      this.$message.warning('单次同时上传文件限制为10个!');
    },
    beforeUpload(file) {
      if (this.multiSelection.length === 0) {
        this.$message.warning('请先选择数据！');
        return false;
      }
      let fileName = file.name.substr(0, file.name.lastIndexOf('.'));
      if (fileName.length > 500) {
        this.$message.warning('文件名字过长! 长度不可大于100!');
        return false;
      }
      if (file.name === ' ' || file.name === null) {
        this.$message.warning('文件名字不能为空');
        return false;
      }
      if (file.size === 0) {
        this.$message.warning('不能上传空文件');
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 300;
      if (!isLt5M) {
        this.$message.error('文件大小不得超过 300MB!');
      }
      this.beforeFlag++;
      return isLt5M;
    },
    uploadSuccess(res) {
      if (res.code === 0) {
        this.$message.success('上传成功！');
        this.buttonRefresh();
      } else {
        this.$message.warning(res.msg);
      }
      this.successFlag++;
      if (this.successFlag === this.beforeFlag) {
        setTimeout(() => {
          this.$refs.upload.clearFiles(); //去掉文件列表
          this.successFlag = 0;
          this.beforeFlag = 0;
        }, 500);
      }
    },
    async initData() {
      await this.getProdControlList();
    },
    //行编辑点击
    editActived({ row }) {
      this.editActivedRow = cloneDeep(row);
    },
    //行编辑点出保存
    editClosed({ row }) {
      if (JSON.stringify(this.editActivedRow) !== JSON.stringify(row)) this.saveRow(row);
    },
    //格式化日期
    changeDateRange(val, index) {
      val = val || [];
      switch (index) {
        case 1:
          let [s1, e1] = val;
          this.$refs.multiTable.searchForm.startTime = s1;
          this.$refs.multiTable.searchForm.endTime = e1;
          return;
        case 2:
          let [s2, e2] = val;
          this.$refs.multiTable.searchForm.startTime2 = s2;
          this.$refs.multiTable.searchForm.endTime2 = e2;
          return;
      }
    },
    //获取数据list
    async getProdControlList() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      this.convertList(searchForm);
      searchForm.inspecation_type = this.radioButton === '成品' ? 0 : 1;
      await qcInspectionApi.get_inspecation(searchForm).then(({ data }) => {
        this.btn = data.btn;
        this.totalPage = data.total;
        this.tableData = data.list;
        this.prodTableProperties.forEach(item => {
          if (item.otherProp === 'stffNameList') item.options = data.stffNameList; //验货员
          if (item.otherProp === 'custAbbrList') item.options = data.custAbbrList; //客户简称
          if (item.otherProp === 'deptNameList') item.options = data.deptNameList; //业务部门
          if (item.otherProp === 'sconStffNameList') item.options = data.sconStffNameList; //销售人员
          if (item.otherProp === 'aqlStandardList') item.options = data.aqlStandardList; //aql标准
          if (item.otherProp === 'prodStffNameList') item.options = data.prodStffNameList; //采购人员
        });
        setTimeout(() => (this.loadFlag = false), 1000);
      });
    },
    //刷新
    buttonRefresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.multiTable.resetFields();
      this.getProdControlList();
    },
    //多选
    handleSelectionChange(val) {
      this.multiSelection = val;
      this.uploadData = {
        ids: getArrayIds(this.multiSelection, 'id', false),
        inspecation_type: this.radioButton === '成品' ? 0 : 1
      };
    },
    //多选过滤
    selectRows(selection) {
      // this.btn.delete = this.helper.isDelete(selection);
    },
    //行保存
    async saveRow(val) {
      let obj = val || this.currentRow;
      const { data } = await qcInspectionApi.edit_inspecation({
        id: obj.id,
        inspecation_type: this.radioButton === '成品' ? 0 : 1,
        complete_time: obj.complete_time, //验货完成时间
        qualification_rate: obj.qualification_rate //验货合格率
      });
    },
    //切换table
    async changeProdControlType(type, val) {
      await this.changeType(type, val);
      await this.getProdControlList();
    },
    async changeType(type, val) {
      this.$refs.multiTable.searchForm.inspecation_type = type === '成品' ? 0 : 1;
      if (type === '材料' && val) this.$refs.multiTable.searchForm.scon_no = val;
      if (type === '成品' && val) this.$refs.multiTable.searchForm.podr_no = val;
      this.radioButton = type;
      await this.$refs.multiTable.reloadTable();
    },
    //格式化保存数据
    convertList(searchForm) {
      searchForm.stffNameList = searchForm.stffNameList?.toString();
      searchForm.custAbbrList = searchForm.custAbbrList?.toString();
      searchForm.deptNameList = searchForm.deptNameList?.toString();
      searchForm.sconStffNameList = searchForm.sconStffNameList?.toString();
      searchForm.aqlStandardList = searchForm.aqlStandardList?.toString();
      searchForm.prodStffNameList = searchForm.prodStffNameList?.toString();
    },
    //导出excel
    exportExcel() {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      this.convertList(searchForm);
      let requestBody = {
        ...searchForm,
        inspecation_type: this.radioButton === '成品' ? 0 : 1
      };
      qcInspectionApi.exlInspectionProd(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.pdf_url, fileName: data.pdf_name, type: '.xlsx' });
      });
    },
    //列变色
    headerCellStyle(column) {
      if (['验货合格率'].includes(column.title)) return { color: 'red' };
    },
    douVisible(row) {
      this.id = row.id;
      this.storageDocumentVisible = true;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.el-drawer__wrapper {
  pointer-events: none;

  ::v-deep .el-drawer__body {
    pointer-events: auto;
  }
}

::v-deep .el-upload {
  width: auto;
  height: auto;
  border: none;
  border-radius: 0;
}
</style>
