<template>
  <div ref="docuDiv" class="vg_wrapper">
    <el-card>
      <el-form>
        <el-row>
          <el-table ref="docuList" :data="tableData" border @selection-change="handleSelectionChange">
            <el-table-column align="center" label="序号" width="60">
              <template v-slot="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="文件名" prop="docu_name" sortable />
            <el-table-column label="文件格式" prop="docu_suffix" />
            <el-table-column label="文件大小" prop="docu_size" />
            <el-table-column label="上传人" prop="uploaded_personnel" />
            <el-table-column :formatter="({ create_time }) => getDate(create_time, true)" label="上传时间" prop="create_time" />
            <el-table-column align="center" label="操作" width="120">
              <template v-slot="scope">
                <el-row justify="space-around" type="flex">
                  <el-col :md="8">
                    <el-link
                      :disabled="scope.row.user_id !== $cookies.get('userInfo').user_id"
                      class="vg_cursor"
                      type="danger"
                      @click="remove(scope.$index, scope.row)"
                      >删除
                    </el-link>
                  </el-col>
                  <el-col :md="8">
                    <el-link v-if="imgarr.indexOf(scope.row.docu_suffix) !== -1" class="vg_cursor" type="warning" @click="seeShow(scope.row)">查看 </el-link>
                    <el-link v-if="'pdf' === scope.row.docu_suffix" class="vg_ml_8 vg_pointer" @click="openPDF(scope.row.docu_url)">查看 </el-link>
                    <el-link v-if="officeFileSuffixList.includes(scope.row.docu_suffix)" class="vg_ml_8 vg_pointer" @click="openOffice(scope.row.docu_url)"
                      >查看
                    </el-link>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-form>
    </el-card>
    <el-dialog :visible.sync="dialogVisible" append-to-body class="showImg" title="图片查看" width="50%">
      <el-image class="showImg" :preview-src-list="[showImgUrl]" :src="showImgUrl" fit="contain"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import { post } from '@api/request';
import { docuAPI } from '@api/modules/docu';
import helper from '@assets/js/helper';
import { getDate } from '@assets/js/dateUtils';
import { qcInspectionApi } from '@api/modules/qcInspection';

export default {
  name: 'documentList',
  props: {
    id: {
      type: Number,
      required: true
    },
    inspecation_type: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      tableData: [],
      docuUrl: '',
      docuName: '',
      dialogVisible: false,
      showImgUrl: '',
      uploadFlag: true,
      beforeFlag: 0,
      successFlag: 0,
      imgarr: [
        'bmp',
        'jpg',
        'png',
        'tif',
        'gif',
        'pcx',
        'tga',
        'exif',
        'fpx',
        'svg',
        'psd',
        'cdr',
        'pcd',
        'dxf',
        'ufo',
        'eps',
        'ai',
        'raw',
        'WMF',
        'webp',
        'avif',
        'apng',
        'jpeg'
      ],
      officeFileSuffixList: [
        'doc',
        'docx',
        'docm',
        'dotm',
        'dotx',
        'xlsx',
        'xlsb',
        'xls',
        'xlsm',
        'pptx',
        'ppsx',
        'ppt',
        'pps',
        'pptm',
        'potm',
        'ppam',
        'potx',
        'ppsm'
      ],
      multipleSelection: [],
      downloadPercentage: 0,
      downloadStatus: '', // 进度条状态，例如：'success', 'exception'
      observer: new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // 交叉区域比例
      })
    };
  },
  mounted() {
    this.observer.observe(this.$refs.docuDiv);
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) this.initData();
      });
    },
    getDate,
    initData() {
      this.getDocus();
    },
    async openPDF(url) {
      window.open(helper.megPath(url));
    },
    openOffice(url) {
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + helper.megPath(url));
    },
    //获取相关文档列表
    async getDocus() {
      await qcInspectionApi
        .get_docus({
          perm_id: 10048,
          form_id: this.id,
          inspecation_type: this.inspecation_type
        })
        .then(({ data }) => {
          this.tableData = data.list;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //单行删除
    remove(index, row) {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(docuAPI.destroyDocuPterById, { docu_pter_id: row.docu_pter_id })
            .then(res => {
              if (res.data.code === 0) {
                this.initData();
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(() => {});
    },
    seeShow(row) {
      this.dialogVisible = true;
      this.showImgUrl = helper.megPath(row.docu_url);
    }
  }
};
</script>

<style scoped lang="scss">
.upload-demo {
  display: flex;
  align-items: baseline;
}

.upload-demo ::v-deep .el-upload--text {
  width: 68px;
  height: auto;
  text-align: left;
  border: none;
  border-radius: 0;
  display: flex;
}

.el-upload__tip {
  margin-bottom: 16px;
}

::v-deep .el-dialog__body {
  text-align: center;
}

.vd_dis {
  display: flex;
}

.vd_posi {
  position: relative;
}

.vd_posi_o {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
