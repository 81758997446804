import { getDateNoTime } from '@assets/js/dateUtils';

export const prodTableProperties = [
  {
    label: '计划验货开始交期',
    prop: 'inspecation_startTime',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: false,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '计划验货结束交期',
    prop: 'inspecation_endTime',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '采购合同号', prop: 'order_no', itemType: 'input', input: true, sortable: true, labelWidth: 120 },
  { label: '供应商', prop: 'supp_abbr', itemType: 'input', input: false, sortable: true, labelWidth: 120 },
  {
    label: '验货员',
    prop: 'stff_name',
    otherProp: 'stffNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: false
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    otherProp: 'custAbbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: false
  },
  { label: '客户货号', prop: 'cust_prod_no', itemType: 'input', input: true, sortable: false, labelWidth: 120 },
  {
    label: '业务部门',
    prop: 'dept_name',
    otherProp: 'deptNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: false
  },
  {
    label: '销售人员',
    prop: 'scon_stff_name',
    otherProp: 'sconStffNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: false
  },
  { label: '验货数量', prop: 'inspecation_num', itemType: 'input', input: false, sortable: true, labelWidth: 100 },
  {
    label: 'AQL标准',
    prop: 'aql_standard',
    otherProp: 'aqlStandardList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: true,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: false
  },
  {
    label: '采购人员',
    prop: 'prod_stff_name',
    otherProp: 'prodStffNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: true,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: false
  },
  {
    label: '报告上传时间',
    prop: 'upload_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '验货完成时间',
    prop: 'complete_time',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      disabled: false,
      type: 'date',
      copyAndPaste: false
    }
  },
  {
    label: '验货合格率',
    prop: 'qualification_rate',
    itemType: 'select',
    options: [
      { value: '合格', label: '合格' },
      { value: '不合格', label: '不合格' },
      { value: '空报告', label: '空报告' }
    ],
    input: true,
    sortable: false,
    labelWidth: 140,
    subItem: {
      disabled: false,
      required: false,
      type: 'select',
      options: [
        { value: '合格', label: '合格' },
        { value: '不合格', label: '不合格' },
        { value: '空报告', label: '空报告' }
      ]
    }
  },
  { label: '验货报告', prop: 'operation', itemType: 'input', input: false, sortable: false, labelWidth: 100 }
];
